import { CountUp } from 'countup.js/dist/countUp.min.js';

import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

jQuery(document).ready(function($) {
	var windowWidth = $(window).width()

/* ====================== MOBILE NAV ======================  */
    $('.menu-toggle').on("click", function() {
        $(this).toggleClass('active');
        $('header nav').toggleClass('open');
        $('header').toggleClass('open');
        $('body').toggleClass('noscroll');
    })

    if(windowWidth < 1280) {
	    $('#menu-main-menu .menu-item-has-children').on("click", function() {
	       $(this).toggleClass('open');
	       $(this).find('.sub-menu').slideToggle();
	    })

	    $('.sub-menu a').on("click", function(event) {
	        event.stopPropagation();
	    });
	}

/* ====================== Header Scroll ======================  */
	var prevScrollpos = window.pageYOffset;
	var currentScrollPos = window.pageYOffset;

	if(currentScrollPos > 120) {
		$("header").addClass("headerscrolled");
	}

	document.addEventListener('scroll', function (event) {
		var currentScrollPos = window.pageYOffset;

		if ((prevScrollpos > currentScrollPos)) {
			$('header').addClass('show');
		} else if((prevScrollpos < currentScrollPos)){
			$('header').removeClass('show');
		}
		else {
			$('header').removeClass('show');
		}

		if(currentScrollPos > 120) {
			$("header").addClass("headerscrolled");
		} else {
			$("header").removeClass("headerscrolled show");
		}

		prevScrollpos = currentScrollPos;
	}, true);

/* ====================== Init Fancybox ======================  */
	Fancybox.bind("[data-fancybox]", {});

/* ====================== Latest Case Studies Carousel ======================  */
	const latest_case_studies = new Swiper('.latest-case-studies .swiper', {
       	slidesPerView: 1,
       	speed: 600,
       	loop: true,
       	centeredSlides: true,
       	watchSlidesProgress: true,
       	spaceBetween: 0,
       	navigation: {
			nextEl: ".latest-case-studies .swiper-button-next",
			prevEl: ".latest-case-studies .swiper-button-prev",
		},
		breakpoints: {
			1024: {
				slidesPerView: 1.7,
				spaceBetween: 0,
			},
		},
	});

/* ====================== Pricing Modules Carousel ======================  */
	const pricing_modules = new Swiper('.pricing-modules .swiper', {
       	slidesPerView: 1,
       	speed: 1200,
       	loop: true,
       	centeredSlides: true,
       	allowTouchMove: false,
       	watchSlidesProgress: true,
       	spaceBetween: 20,
       	navigation: {
			nextEl: ".pricing-modules .swiper-button-next",
			prevEl: ".pricing-modules .swiper-button-prev",
		},
	});

/* ====================== Submit Gravity Form ======================  */
	$(document).on('gform_post_render', function(){
	     $('.gform_wrapper .btn').on('click', function() {
        	$(this).parents('.gform_wrapper').find('input[type=submit]').click();
        });
	});

/* ====================== Accordion ======================  */
	if($('.faqs').length) {
		var faq_anchor = getParameterByName('faq');
		if(faq_anchor) {
			var selectedFAQ = $('.faqs [anchor-related="'+ faq_anchor +'"');

			$(selectedFAQ).addClass('active');
			$(selectedFAQ).find('.row-title').addClass('active');
			$(selectedFAQ).find('.row-content').slideDown();
			$(selectedFAQ).find('.row-content').addClass('active');

			$('html,body').animate({
		   		scrollTop: $(selectedFAQ).offset().top -250
			});
		}
	}

	$('.faqs .row-title').on('click', function () {
		if($(this).hasClass('active')) {
			$(this).removeClass('active');
			$(this).parent('.row').removeClass('active');
			$(this).next('.row-content').slideUp();
			$(this).next('.row-content').removeClass('active');
		} else {
			$('.accordion-element .row-title, .accordion-element .row').removeClass('active');
			$('.row-content').slideUp();
			$('.row-content').removeClass('active');

			$(this).parent('.row').addClass('active');
			$(this).addClass('active');
			$(this).next('.row-content').slideDown();
			$(this).next('.row-content').addClass('active');
		}
    });

/* ====================== GSAP ======================  */
	let animate = gsap.utils.toArray('.animate')
	animate.forEach((item, index) => {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				once: true,
				start:"0 80%",
				toggleActions:"restart none none reset",
				end: "500",
				markers: false,
				toggleClass: 'animateelement'
			}
		});
	});


/* ====================== Animating Stats ======================  */
	$('.stats-row .number .target').each(function(){
		var endVal = $(this).text()
		const countUp = new CountUp(this, endVal, { enableScrollSpy: true });
	});

/* ====================== Smooth Scroll - Doesn't conflict with ajax smooth scroll ======================  */
	// Add smooth scrolling to all links
	$("a").on('click', function(event) {

	// Make sure this.hash has a value before overriding default behavior
	if (this.hash !== "") {
	  // Prevent default anchor click behavior
	  event.preventDefault();

	  // Store hash
	  var hash = this.hash;

	  // Using jQuery's animate() method to add smooth page scroll
	  // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
	  $('html, body').animate({
	    scrollTop: $(hash).offset().top
	  }, 500, function(){

	    // Add hash (#) to URL when done scrolling (default click behavior)
	    window.location.hash = hash;
	  });
	} // End if
	});
});
